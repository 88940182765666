import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
import SuccessModal from "./SuccessModal";
import {
  getDisplayDateFromStartEndDateString,
  randomString,
  useScrollToLocation,
} from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const tag = "sisomni";

// const companies = [
//   {
//     id: "1",
//     value: "BlackHat",
//     date: "August 5 – 10",
//     location: "Las Vegas, NV",
//     img: "blackhat.png",
//   },
//   {
//     id: "2",
//     value: "Hubspot INBOUND",
//     date: "September 5 – 8",
//     location: "Boston, MA",
//     img: "hubspot.png",
//   },
//   {
//     id: "3",
//     value: "Dreamforce",
//     date: "September 12 – 14",
//     location: "San Francisco, CA",
//     img: "dreamforce.png",
//   },
//   {
//     id: "4",
//     value: "Groceryshop",
//     date: "September 18 – 21",
//     location: "Las Vegas, NV",
//     img: "groceryshop.png",
//   },
//   {
//     id: "5",
//     value: "HLTH",
//     date: "October 8 – 11",
//     location: "Las Vegas, NV",
//     img: "hlth.png",
//   },
//   {
//     id: "6",
//     value: "Industrial Transformation USA",
//     date: "October 10 – 12",
//     location: "Indianapolis, IN",
//     img: "industrial-trans.png",
//   },
//   {
//     id: "7",
//     value: "Money20/20",
//     date: "October 22 – 25",
//     location: "Las Vegas, NV",
//     img: "money2020.png",
//   },
//   {
//     id: "8",
//     value: "AWS RE:invent",
//     date: "November TBD",
//     location: "Las Vegas, NV",
//     img: "reinvent2023.png",
//   },
// ];

const expertiseOptions = [
  "Brand Purpose Development",
  "CSR-ESG-DEI Strategy and Positioning",
  "Landscape Analysis",
  "Purpose Amplification",
  "Narrative Development",
  "Digital Channel Evolution",
  "CSR-ESG-DEI or NPO Program Design",
  "Brand Activation/ Impact Campaign",
];

const capitalOptions = [
  "Raising a Seed Round of up to $5 million",
  "Raising a Series A Round between $10 million to $20 million",
  "Raising a Financing Round of more than $20 million",
];

const placementOptions = [
  "Looking to fill an impact role at an organization (junior to senior)",
  "Seeking a subject-matter expert or industry luminary for a board position",
  "Searching for a dynamic executive speaker to bring their thought leadership to our panel, mainstage, or convening",
];

const clubOptions = ["I am interested in becoming a member of SILC"];

export default function Request() {
  const [isShown, setIsShown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [events, setEvents] = useState([]);

  const [showAccordion, setShowAccordion] = useState(false);
  const toggleAccordion = () => {
    setShowAccordion(!showAccordion);
  };

  useScrollToLocation();

  // companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const api = new ApiService();
        const response = await api.get(
          `landmark/portfolio/getCompanies/${tag}`
        );
        console.log(
          `fetchCompanies() - landmark/portfolio/getCompanies/${tag}`,
          response
        );
        if (response && response.length && response[0].companyId) {
          setCompanies(response);
        } else {
          throw "Couldn't get companies";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchCompanies();
  }, []);
  // events
  // useEffect(() => {
  //   const fetchEvents = async () => {
  //     try {
  //       const api = new ApiService();
  //       const response = await api.get(`landmark/events/getEvents/${tag}`);
  //       console.log(
  //         `fetchEvents() - landmark/events/getEvents/${tag}`,
  //         response
  //       );
  //       if (response && response.length && response[0].eventId) {
  //         // sort events by start date
  //         response.sort((a, b) => {
  //           return a.startDateEpoch - b.startDateEpoch;
  //         });
  //         setEvents(response);
  //       } else {
  //         throw "Couldn't get events";
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       toast.error("Couldn't load data from server");
  //     }
  //   };
  //   fetchEvents();
  // }, []);
  const [form, setForm] = useState({
    companies: [],
    expertiseOptions: [],
    capitalOptions: [],
    placementOptions: [],
    clubOptions: [],
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    notes: "",
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  // optionsName - e.g. expertiseOptions
  const optionOnChange = (e, optionsName) => {
    if (e.target.checked) {
      let arr = [...form[optionsName]];
      arr.push(e.target.id);
      const obj = {};
      obj[optionsName] = arr;
      updateForm(obj);
    } else {
      const obj = {};
      obj[optionsName] = form[optionsName].filter(
        (item) => item !== e.target.id
      );
      updateForm(obj);
    }
  };

  const CheckboxOptionsSection = ({
    sectionClassname = "",
    anchorId,
    title,
    description,
    optionsName,
    options,
    accordionHtml,
  }) => {
    const [showAccordion, setShowAccordion] = useState(false);
    const toggleAccordion = () => {
      setShowAccordion(!showAccordion);
    };
    return (
      <section className={`request ${sectionClassname}`} id={anchorId}>
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading">{title}</h3>
              <h5 className="request-subheading">{description}</h5>
              <div className="request-container">
                <label
                  className={`input__outer ${accordionHtml ? `mb-1` : ``}`}
                >
                  {options.map((option, index) => (
                    <OptionCheckbox
                      key={`option_${index}`}
                      option={option}
                      optionsName={optionsName}
                    />
                  ))}
                </label>
                {accordionHtml && (
                  <div className="learn-more-accordion-toggle">
                    <p>
                      <span
                        onClick={toggleAccordion}
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        Learn More
                        <span style={{ marginLeft: "5px" }}>
                          <FontAwesomeIcon
                            icon={showAccordion ? faChevronUp : faChevronDown}
                          />
                        </span>
                      </span>
                    </p>
                  </div>
                )}

                {showAccordion ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    exit={{ opacity: 0 }}
                  >
                    {accordionHtml}
                  </motion.div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    console.log(`submitForm()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/portfolio/submitForm`, {
        form,
        tag,
      });
      console.log(`submitForm() - response`, response);
      // toast.success("Your response has been logged. Thank you!");
      setIsShown(true);
      // setCompanies(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  if (!companies || !companies.length) {
    return null;
  }

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const {
      companies,
      expertiseOptions,
      capitalOptions,
      placementOptions,
      clubOptions,
      firstName,
      lastName,
      company,
      jobTitle,
      email,
    } = form;
    // must select at least 1 company
    const allOptionsLength =
      expertiseOptions.length +
      capitalOptions.length +
      placementOptions.length +
      clubOptions.length;
    if (!companies.length && !allOptionsLength) {
      formErrorMsg =
        "Please select at least one checkbox option or portfolio company";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  const OptionCheckbox = ({ option, optionsName }) => {
    const id = `${option}`;
    return (
      <label htmlFor={id} className="check">
        <div className="check__box">
          <input
            type="checkbox"
            id={id}
            checked={form[optionsName].some((p) => p === option)}
            onChange={(event) => optionOnChange(event, optionsName)}
          />
          <span>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
              alt="icon"
            />
          </span>
        </div>
        <div className="check-label">{option}</div>
      </label>
    );
  };

  const SectionOverview = ({
    title,
    description,
    descriptionClass = "",
    imagePath,
    buttonText,
    buttonAnchor,
  }) => {
    return (
      <div className="col-12 col-lg-4">
        <div className="topic-container">
          <div className="title">{title}</div>
          <div className={`desc ${descriptionClass}`}>{description}</div>
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + imagePath}
            alt={title}
          />
          {/* <a href={`#${buttonAnchor}`}>
            <div className="action-btn">
              <div>{buttonText}</div>
            </div>
          </a> */}
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="overview-section">
        <div className="section-header-text">
          <div className="sis-omni-logo">
            <img
              className="img-fluid"
              src={process.env.PUBLIC_URL + `/images/sisomni/sis-omni-logo.png`}
              alt="SIS Omni Logo"
            />
          </div>
          <h4 className="title">
            Welcome to SIS Omni at Landmark Ventures, born out of the Social
            Innovation Summit. We drive transformation across impact initiatives
            within CSR, ESG, DEI, and more.
          </h4>
          <br />
          <h4 className="title">
            Please select from any of the options below that are relevant for
            you at this time, and a member of our team will follow up shortly.
            We look forward to our continued partnership and collaboration.
          </h4>
        </div>
      </section>
      <section className="topics-section">
        <div className="auto__container">
          <div className="row">
            <SectionOverview
              title={"Strategic Expertise"}
              description={
                "Showcasing the top insights and intelligence from our network of impact executives."
              }
              imagePath={"/images/sisomni/topic_pic_3.jpg"}
              buttonText={"Schedule a Briefing"}
              buttonAnchor={"expertise"}
            />
            <SectionOverview
              title={"Capital Raising"}
              description={
                "Pathways for impact entrepreneurs to get off the ground or expand their work."
              }
              imagePath={"/images/sisomni/topic_pic_4.jpg"}
              buttonText={"Discuss a Strategy"}
              buttonAnchor={"capital"}
            />
            <SectionOverview
              title={"Industry Connect"}
              description={
                "Deep connections with impact leaders for open positions, boards, speaking roles, and more."
              }
              imagePath={"/images/sisomni/topic_pic_1.jpg"}
              buttonText={"Find an Executive"}
              buttonAnchor={"industryconnect"}
            />
          </div>
          {/* <div className="row center-last-row">
            <SectionOverview
              title={"Impact Portfolio"}
              description={
                "Including the most innovative organizations driving impact and change."
              }
              imagePath={"/images/sisomni/topic_pic_4.jpg"}
              buttonText={"Request a Meeting"}
              buttonAnchor={"impact"}
            />
            <SectionOverview
              title={"Peer Connect"}
              description={
                "Informal collaboration with highly respected industry leaders and experts."
              }
              // descriptionClass={"smaller"}
              imagePath={"/images/sisomni/topic_pic_5.jpg"}
              buttonText={"Join a Conversation"}
              buttonAnchor={"connect"}
            />
          </div> */}
        </div>
      </section>
      {/* <section className="request top" id="events">
        <div className="auto__container">
          <div className="request__inner">
            <div className="requestForm">
              <h3 className="request-heading">Strategic Events</h3>
              <h5 className="request-subheading">
                If you are interested in our events, please select one or more
                below:
              </h5>
              <div className="requestForm__row">
                {events && events.length
                  ? events.map((item, index) => {
                      return (
                        <RequestItemEvent
                          itemData={item}
                          key={index}
                          updateForm={updateForm}
                          form={form}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <CheckboxOptionsSection
        anchorId={"expertise"}
        title={"Strategic Expertise"}
        description={
          "Would you like to schedule a briefing to discuss an impact initiative you are establishing or strengthening? Select all relevant topics:"
        }
        optionsName={"expertiseOptions"}
        options={expertiseOptions}
        accordionHtml={
          <div className="req-accordion">
            <p>
              With our deep network of private, public, and social sector
              partners, SIS Omni is well positioned to provide insights to your
              CSR-ESG-DEI initiatives given our unparalleled market knowledge
              across topic areas such as:
            </p>

            <div className="row" style={{ marginTop: "15px" }}>
              <div className="col-12 col-sm-6">
                <ul className="pl-0">
                  <li>• Inclusive Entrepreneurship</li>
                  <li>• Mental Health</li>
                  <li>• Tech for Good</li>
                  <li>• Digital Equity</li>
                  <li>• Sustainability</li>
                  <li>• Health Equity</li>
                </ul>
              </div>
              <div className="col-12 col-sm-6">
                <ul className="pl-0">
                  <li>• Racial and Social Justice</li>
                  <li>• Gender Equality</li>
                  <li>• Community Development and Economic Vitality</li>
                  <li>• Diversity, Equity, Inclusion, and Proximity</li>
                  <li>• Future of Work and Lifelong Learning</li>
                  <li>• STEM and Education</li>
                </ul>
              </div>
            </div>
            <p>
              We have extensive personal relationships derived from working with
              many companies, foundations, investors, policy centers, and
              nonprofits that are scaling social impact, including Comcast
              NBCUniversal, PwC, Lululemon, Kauffman Foundation, KPMG, Best Buy,
              Cruise, Caterpillar, Horizon, Blackbaud, BlackRock, Annenberg
              Foundation, UBS, AARP, Lyft, Discovery, Blackstone, Citi, and
              Walton Family Foundation, to name a few of our more than 500+
              recurring clients, partners, and speakers.
            </p>
            <p style={{ marginTop: "10px" }}>
              Our industry-leading{" "}
              <a href="https://socialinnovation.com" target="_blank">
                summits
              </a>{" "}
              have served for almost two decades as a hub to engage with tens of
              thousands of executives and extract their approaches and best
              practices to initiatives similar to our client projects. Our
              process is anchored on our greatest asset: tapping into our strong
              friendships in the SIS community to ask them questions, get
              feedback, and leverage introductions that translate into powerful
              guidance and results for our clients.
            </p>
          </div>
        }
      />
      <CheckboxOptionsSection
        anchorId={"capital"}
        title={"Capital Raising"}
        description={
          "With a strong network of active tech investors and innovation leaders, we offer valuable funding opportunities for impact entrepreneurs looking to scale their projects through fully managed equity crowdfunding rounds or Landmark’s investment banking group. Choose your current status:"
        }
        optionsName={"capitalOptions"}
        options={capitalOptions}
        accordionHtml={
          <div className="req-accordion">
            <p>
              SIS Omni supports impact entrepreneurs at two points in their
              capital-raising lifecycle:
            </p>

            <h4>Seed Round ($500K-$5MM)</h4>
            <ul className="pl-0">
              <li>
                • SIS Omni offers fully managed Equity Crowdfunding rounds to
                clients, including:
              </li>
              <ul className="pl-1">
                <li>
                  ◦ Collateral build, Wefunder page setup, strategy
                  consulting/implementation
                </li>
                <li>◦ Access to 100K+ potential funders to target</li>
                <li>
                  ◦ Founders only participate in engaging their audience (with
                  our help), financials, and Form C data collection
                </li>
              </ul>
              {/* <li>• Preferred Wefunder pricing of 6.5% for raises over $1MM</li> */}
              <li>
                • One month of preparation + one month of private raising + one
                month of public raising
              </li>
            </ul>

            <h4>Series A ($10MM-$20MM)</h4>
            <p>
              Our expertise, growth focus, and unique ecosystem allow us to
              find, advance, and close the right deal for our clients, in scale
              and size. We have strong working relationships with key investors
              and acquirers across the Corporate/Strategic, Growth and Private
              Equity, Mid/Late Stage Venture, Family Office, and Non-Traditional
              Institutional Investment communities. Landmark’s investment
              banking group is highly focused and deeply experienced in managing
              complex transactions, including private placements, mergers and
              acquisitions, and strategic advisory assignments. Our industry
              focus and expertise spans the “Innovation Economy” including Tech
              and Digital Engagement.
            </p>

            <h4>The SIS Omni Advantage</h4>
            <p>
              A key component of a Community Round is starting with a large
              audience. Through SIS Omni, we jumpstart your Community Round with
              our potential list of 100K+ innovation leaders to target. Plus,
              we’ve partnered with a former founder-in-residence at WeFunder to
              bring his expertise and guidance to every step of your Community
              Round.
            </p>
          </div>
        }
      />
      <CheckboxOptionsSection
        anchorId={"industryconnect"}
        title={"Placement"}
        description={"Request all relevant services:"}
        optionsName={"placementOptions"}
        options={placementOptions}
        accordionHtml={
          <div className="req-accordion">
            <p>
              Producing almost two decades of highly curated events across the{" "}
              <a href="https://socialinnovation.com/" target="_blank">
                Social Innovation Summit
              </a>{" "}
              (SIS) platform, we have authentic relationships with a wide range
              of outstanding impact leaders who are a perfect fit for your open
              position, board seat, and speaking role.
            </p>
            <p>
              Due to our extensive SIS subject-matter expertise across 40+
              topics and themes, we know your purpose-driven initiatives and
              understand the leader you need at the table. Our process puts you
              in front of social impact professionals who are actively engaged
              with the issues and experienced in the work.
            </p>
            <ul className="pl-1">
              <li>• 6,000 SIS Speakers</li>
              <li>• 60 Countries Represented</li>
              <li>• 5,000 Participating Companies</li>
              <li>• 75% Executive Roles</li>
            </ul>
          </div>
        }
      />
      <section className="request" id="portfolio">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading">Impact Portfolio</h3>
              <h5 className="request-subheading">
                To request more information about our portfolio companies,
                please select one or more below:
              </h5>
              <div className="requestForm__row unified">
                {companies && companies.length
                  ? companies.map((item, index) => {
                      return (
                        <RequestItemCompany
                          itemData={item}
                          key={index}
                          updateForm={updateForm}
                          form={form}
                        />
                      );
                    })
                  : null}
              </div>
              <div className="learn-more-accordion-toggle mt-2">
                <p>
                  <span
                    onClick={toggleAccordion}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    Learn More
                    <span style={{ marginLeft: "5px" }}>
                      <FontAwesomeIcon
                        icon={showAccordion ? faChevronUp : faChevronDown}
                      />
                    </span>
                  </span>
                </p>
              </div>
              {showAccordion ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="req-accordion">
                    <p className="mb-2">
                      SIS Omni provides valuable introductions to our deep
                      network of cross-sector and cross-industry executive
                      contacts in order to identify and arrange highly qualified
                      partnership opportunities for our clients. We have
                      unparalleled entrée through our comprehensive subject
                      matter expertise and deep access to organizations,
                      providing the ability to accelerate your growth, connect
                      you to potential partners, and expand your initiatives. We
                      also host Virtual Roundtables with leading experts and
                      Executive Briefings that spotlight innovation trends
                      likely to impact client-led initiatives. Through our
                      unique approach, we’ve helped many corporate and nonprofit
                      social impact programs and initiatives build collaborative
                      connections that amplify impact.
                    </p>
                    {/* <p className="mb-2">
                      Our goal is to translate unique market insights and
                      executive relationships with key decision makers to
                      uncover near-term opportunities for CSR-ESG-DEI and
                      nonprofits across a range of top global organizations.
                    </p>
                    <p className="mb-2">
                      We help organizations quickly build or expand their
                      pipeline of strategic relationships across sectors and
                      industries, specific geographies, target markets, and/or
                      named marquee partners. Through our unique approach, we’ve
                      helped many corporate and nonprofit social impact programs
                      and initiatives build collaborative connections that
                      amplify impact.
                    </p> */}
                  </div>
                </motion.div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <CheckboxOptionsSection
        sectionClassname={""}
        anchorId={"social"}
        title={"Social Innovation Leadership Council (SILC)"}
        description={
          "As part of an intimate, invite-only, open-source community, our highly respected network of curated CSR-ESG-DEI executives can connect with one another, seek quick advice, share best practices, and collaborate in an informal and supportive setting. Request to join:"
        }
        optionsName={"clubOptions"}
        options={clubOptions}
        accordionHtml={
          <div className="req-accordion">
            <p>
              SILC is an intimate, invite-only, open-source community in which
              our highly respected network of curated CSR-ESG-DEI executives can
              connect with a truly leading group of influencers to seek quick
              advice, share best practices, and collaborate in an informal and
              supportive setting. SILC also serves as an Advisory Board to the
              Social Innovation Summit helping to plan, support, and drive
              content and ideas. Members receive preferred opportunities to
              suggest Summit themes and nominate speakers aligned with specifics
              topics. In addition to the Social Innovation Summit, SILC members
              have priority access to attend and speak at other Landmark
              Ventures events, including: Dealmakers Summit, CIO Summit, plus
              various VIP Dinners, Salons, and Virtual Roundtables. The true
              benefit of SILC is to have a close-knit circle of CSR, ESG, and
              DEI experts at your fingertips through a shared community
              conversation as you have questions or requests for ideas,
              resources, connections, insights, and more.
            </p>
          </div>
        }
      />
      <section className="request last">
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}
            <div className="requestForm">
              <h3 className="request-heading" style={{ marginBottom: "12px" }}>
                About You
              </h3>
              <div className="about-you-container">
                <div className="requestForm__inputs">
                  <label className="input__outer sm">
                    <h5>First Name</h5>
                    <div className="input">
                      <input
                        type="name"
                        value={form.firstName}
                        onChange={onChangeInput("firstName")}
                      />
                    </div>
                  </label>
                  <label className="input__outer sm">
                    <h5>Last Name</h5>
                    <div className="input">
                      <input
                        type="text"
                        value={form.lastName}
                        onChange={onChangeInput("lastName")}
                      />
                    </div>
                  </label>
                  <label className="input__outer">
                    <h5>Company</h5>
                    <div className="input">
                      <input
                        type="text"
                        value={form.company}
                        onChange={onChangeInput("company")}
                      />
                    </div>
                  </label>
                  <label className="input__outer">
                    <h5>Job title</h5>
                    <div className="input">
                      <input
                        type="text"
                        value={form.jobTitle}
                        onChange={onChangeInput("jobTitle")}
                      />
                    </div>
                  </label>
                  <label className="input__outer">
                    <h5>Email</h5>
                    <div className="input">
                      <input
                        type="email"
                        value={form.email}
                        onChange={onChangeInput("email")}
                      />
                    </div>
                  </label>
                  <label className="input__outer mb-0">
                    <h5>
                      Please share any additional notes on your personal
                      interest areas and active initiatives that may help us
                      curate the most relevant solutions for you (optional):
                    </h5>
                    <div className="input">
                      <input
                        type="text"
                        value={form.notes}
                        onChange={onChangeInput("notes")}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                onClick={submitForm}
                className={`button submit-btn ${submitDisabledClass}`}
              >
                Submit
              </button>
              {formErrorMsg.length ? (
                <div className="form-invalid-msg">{formErrorMsg}</div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <AnimatePresence>
        {isShown && <SuccessModal setIsShown={setIsShown} />}
      </AnimatePresence>
    </>
  );
}

const RequestItemEvent = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.events];
      arr.push(props.itemData);
      props.updateForm({ events: arr });
      console.log(
        `RequestItemEvent() - form events updated - event added`,
        arr
      );
    } else {
      const adjustedArr = props.form.events.filter(
        (item) => item.eventId !== props.itemData.eventId
      );
      props.updateForm({
        events: adjustedArr,
      });
      console.log(
        `RequestItemEvent() - form events updated - event removed`,
        adjustedArr
      );
    }
  };

  return (
    <div className="col-12 col-sm-6">
      <div className="req-item event">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="req-item-content">
          <div className="req-item-upper">
            <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">
                {getDisplayDateFromStartEndDateString(
                  props.itemData.startDate,
                  props.itemData.endDate,
                  props.itemData.endDateEpoch
                )}
              </p>
              <p className="sm long-desc">{props.itemData.location}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    </div>
  );
};

const RequestItemCompany = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.companies];
      arr.push(props.itemData);
      props.updateForm({ companies: arr });
      console.log(
        `RequestItemCompany() - form companies updated - company added`,
        arr
      );
    } else {
      const adjustedArr = props.form.companies.filter(
        (item) => item.companyId !== props.itemData.companyId
      );
      props.updateForm({
        companies: adjustedArr,
      });
      console.log(
        `RequestItemCompany() - form companies updated - company removed`,
        adjustedArr
      );
    }
  };

  let checkboxMode = true;

  const randomId = randomString(6);

  if (checkboxMode) {
    return (
      <div className="req-item">
        <div className="req-item-content">
          <div className="req-item-upper">
            <div className="check__box adj">
              <input
                type="checkbox"
                id={randomId}
                checked={checked}
                onChange={onChange}
              />
              <span>
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                  alt="icon"
                />
              </span>
            </div>
            <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="req-item">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <div className="req-item-content">
        <div className="req-item-upper">
          <div className="req-item-img">
            <img className="img-fluid" src={props.itemData.imageUrl} />
          </div>
          <div className="req-item-header">
            <h5>{props.itemData.title}</h5>
            <p className="sm short-desc">{props.itemData.shortDescription}</p>
            <p className="sm long-desc">{props.itemData.longDescription}</p>
          </div>
        </div>
        {/* <div className="req-item-lower">
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="company-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
